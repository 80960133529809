.shopwarpper{overflow: hidden; padding: 50px 0 0; position: relative;}
.shopwarpper2{margin: 0 auto; max-width: 55rem; position: relative;}
.pricePlan, .topLogBox .Polaris-Box{position: relative;}
.topLogBox .Polaris-Box{display: flex; height: 100px;}
.topLogBox .Polaris-Box .Polaris-AlphaStack{width: 100%; align-items: center;}
.imagewrape{ border: 1px solid var(--p-border-success); position: absolute; top: 0; left: 0; right: 0; bottom: 0; border-radius: var(--p-border-radius-2); opacity: 0; cursor: pointer;}
.imagewrape .Polaris-Icon{ position: absolute; top: 10px; right: 10px;}
.imagewrape.active{opacity: 1;}
.imagewrape:hover{opacity: 1;}
.pricePlan .Polaris-Badge{position: absolute; top: -29px; right: 0;}
.shopwarpper .Polaris-Collapsible--isFullyClosed{display: block; margin-top: 20px;}
.videoThumbnail.hidden{display: none;}
.iframeVideo.hidden{display: none;}
.iframeVideo iframe{ width: 100%; height: 490px; border: 0;}
.changeLog .Polaris-Layout__Annotation{padding: 0;}
.changeLog .Polaris-Layout__Annotation .Polaris-Box{margin-top: 0;}
@media (min-width: 30.625em){
	.supportBox .Polaris-CalloutCard__Image{ margin-left: var(--p-space-8); margin-right: var(--p-space-5);     width: 12rem;}
}